<template>
  <div class="app" ref="header">

    <el-container :style="tableHeight">
      <el-header style="padding: 0;">
        <div class="header-item query">
          <el-input
            placeholder="查询"
            suffix-icon="el-icon-search"
            v-model="queryDb.query" style="width: 286px;">
          </el-input>
        </div>
        <div class="header-item nav">
          <el-col :sm="8" class="menu">
            <el-button size="small" type="info" @click="resetForm" v-if="formStatus && formStatus != 'info'">
              取消</el-button>
            <el-button size="small" type="warning" @click="resetForm" v-if="formStatus && formStatus == 'info'">
              <i class="fa fa-reply" aria-hidden="true"></i> 返回</el-button>
          </el-col>
          <el-col :sm="8" class="title">{{formTitle}}</el-col>
          <el-col :sm="8" class="date">.</el-col>
        </div>
      </el-header>
      <el-container :style="tableHeight">
        <el-aside width="300px">

          <template>
              <el-row :sm="24"
                      :class="['item',{active:categoryIndex == 'item-'+item.id}]"
                      v-for="(item,index) in data.list" :key="index">
                <div @click="handleCreate('info',item)">
                  <el-col :sm="24" class="head">
                    <el-col :sm="6">
                      <el-col :sm="24">
                        <el-avatar shape="square" :size="60" :src="imgHeadUrl"></el-avatar>
                        <el-image class="vip" :src="imgVipUrl"></el-image>
                      </el-col>
                    </el-col>
                    <el-col :sm="18">
                      <el-col :sm="24">
                        <div class="name">
                          {{item.name}}
                        </div>
                        <el-tag class="store-title" size="mini" type="success" effect="plain">{{item.storeName}}</el-tag>
                      </el-col>
                      <el-col :sm="24">
                        <div class="title line-limit-length">
                          <i class="fa fa-tty"></i> {{item.phone}} {{item.phone2?","+item.phone2:''}}
                        </div>
                        <div class="title line-limit-length">
                          <i class="fa fa-id-card-o"></i> <b>{{item.memberId}}</b>
                        </div>
                      </el-col>
                    </el-col>
                  </el-col>
                </div>
              </el-row>

              <el-row :sm="24" class="pager">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  @current-change="pageBtn"
                  :page-size="data.pageSize"
                  :total="data.total"
                ></el-pagination>
              </el-row>
          </template>

        </el-aside>
        <el-container>
          <el-main>
            <template v-if="formStatus">

              <!-- 用戶信息 -->
              <div class="user-card" style="padding-bottom: 25px" >
                <div class="bg" style="height: 140px;">
                  <div class="item avatar">
                    <el-avatar shape="square" :size="128" :src="imgHeadUrl"></el-avatar>
                    <el-image class="vip" :src="imgVipUrl"></el-image>
                  </div>
                  <div class="item info">
                    <div class="date">
                      <el-row>
                        <el-col :md="12">編號：{{form.id}}</el-col>
                        <el-col :md="12" class="time">{{form.creationTime}}</el-col>
                      </el-row>
                    </div>
                    <div class="name">姓名：{{form.name}} <el-tag size="mini" type="success" effect="plain">{{form.storeName}}</el-tag></div>
                    <div class="age">年齡：{{form.age}}</div>
                    <div class="phone line-limit-length">手提：{{form.phone}} {{form.phone2?","+form.phone2:''}}</div>
                    <div class="store">郵箱：{{form.mail}}</div>
                    <div class="store">會員號碼：{{form.memberId}}</div>
                  </div>
                </div>
              </div>

              <!-- <el-tabs v-model="activeName">
                <el-tab-pane label="購買历史" name="first">
                  <el-table
                    class="lists"
                    ref="RoomTable"
                    :data="data.list"
                    :height="data.total?clientHeight:'100'"
                    v-loading="loading"
                    border
                    style="width: 100%;"
                  >
                    <el-table-column fixed label="頭像" width="90">
                      <template slot-scope="scope">
                        <img
                          class="table-user-head"
                          :src="scope.row.avatarUrl?scope.row.avatarUrl:userHeadImg"
                        />
                      </template>
                    </el-table-column>
                    <el-table-column fixed prop="account" min-width="120" label="賬戶號碼"></el-table-column>
                    <el-table-column prop="name" min-width="120" label="用戶名稱"></el-table-column>
                    <el-table-column prop="phone" label="手提號碼" width="120"></el-table-column>
                    <el-table-column label="性別" width="80">
                      <template slot-scope="scope">
                        <div style="text-align: center;">
                          <el-tag :type="sexColor[scope.row.sex]">{{sexType[scope.row.sex]}}</el-tag>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="groupId" label="權限組" width="120">
                      <template slot-scope="scope">
                        <div style="text-align: center;">
                          {{getGroupName(scope.row.groupId)}}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="鎖定狀態" width="80">
                      <template slot-scope="scope">
                        <div style="text-align: center;">
                          <el-switch
                            v-model="scope.row.isLock"
                            active-color="#ff4949"
                            inactive-color="#eee"
                            @change="doLocked(scope.row.id, scope.row.isLock)"
                          ></el-switch>
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column label="登入次數" width="100">
                      <template slot-scope="scope">
                        <div style="text-align: center;">{{scope.row.logEntry}} 次</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="logIp" label="登入IP" width="120"></el-table-column>
                    <el-table-column prop="logTime" label="最新登入時間" width="180"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="130">
                      <template slot-scope="scope">
                        <div style="text-align: center;">
                          <el-dropdown
                            split-button
                            type="primary"
                            size="small"
                            @command="actionBtn"
                            v-if="scope.row.id !=1"
                          >
                  <span @click="addToEdit(scope.row.id)">
                    <i class="el-icon-edit-outline" style="font-weight: 900;"></i> 編輯
                  </span>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item :command="{id:scope.row.id,name:'recycle'}">
                                <i class="fa fa-recycle"></i>回收
                              </el-dropdown-item>
                              <el-dropdown-item
                                style="color: #F56C6C;"
                                :command="{id:scope.row.id,name:'trash'}"
                                divided
                              >
                                <i class="fa fa-trash-alt"></i>刪除
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                          <div v-else>
                            <el-button
                              type="primary"
                              size="small"
                              v-if="$store.state.userInfo.adminId == 1"
                              @click="addToEdit(scope.row.id)"
                            >
                              <i class="el-icon-edit-outline" style="font-weight: 900;"></i> 编辑
                            </el-button>
                            <el-button
                              size="small"
                              type="info"
                              @click="$refs.RoomTable.clearSelection()"
                              style="margin-right: 8px;"
                              v-else
                            >禁止操作</el-button>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="pager">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      @current-change="pageBin1"
                      :page-size="data.pageSize"
                      :total="data.total"
                    ></el-pagination>
                  </div>
                </el-tab-pane>

                <el-tab-pane label="預約历史" name="second">
                  <el-table
                    class="lists"
                    ref="RoomTable"
                    :data="data.list"
                    :height="data.total?clientHeight:'100'"
                    v-loading="loading"
                    border
                    style="width: 100%;"
                  >
                    <el-table-column fixed label="頭像" width="90">
                      <template slot-scope="scope">
                        <img
                          class="table-user-head"
                          :src="scope.row.avatarUrl?scope.row.avatarUrl:userHeadImg"
                        />
                      </template>
                    </el-table-column>
                    <el-table-column fixed prop="account" min-width="120" label="賬戶號碼"></el-table-column>
                    <el-table-column prop="name" min-width="120" label="用戶名稱"></el-table-column>
                    <el-table-column prop="phone" label="手提號碼" width="120"></el-table-column>
                    <el-table-column label="性別" width="80">
                      <template slot-scope="scope">
                        <div style="text-align: center;">
                          <el-tag :type="sexColor[scope.row.sex]">{{sexType[scope.row.sex]}}</el-tag>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="groupId" label="權限組" width="120">
                      <template slot-scope="scope">
                        <div style="text-align: center;">
                          {{getGroupName(scope.row.groupId)}}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="鎖定狀態" width="80">
                      <template slot-scope="scope">
                        <div style="text-align: center;">
                          <el-switch
                            v-model="scope.row.isLock"
                            active-color="#ff4949"
                            inactive-color="#eee"
                            @change="doLocked(scope.row.id, scope.row.isLock)"
                          ></el-switch>
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column label="登入次數" width="100">
                      <template slot-scope="scope">
                        <div style="text-align: center;">{{scope.row.logEntry}} 次</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="logIp" label="登入IP" width="120"></el-table-column>
                    <el-table-column prop="logTime" label="最新登入時間" width="180"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="130">
                      <template slot-scope="scope">
                        <div style="text-align: center;">
                          <el-dropdown
                            split-button
                            type="primary"
                            size="small"
                            @command="actionBtn"
                            v-if="scope.row.id !=1"
                          >
                  <span @click="addToEdit(scope.row.id)">
                    <i class="el-icon-edit-outline" style="font-weight: 900;"></i> 編輯
                  </span>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item :command="{id:scope.row.id,name:'recycle'}">
                                <i class="fa fa-recycle"></i>回收
                              </el-dropdown-item>
                              <el-dropdown-item
                                style="color: #F56C6C;"
                                :command="{id:scope.row.id,name:'trash'}"
                                divided
                              >
                                <i class="fa fa-trash-alt"></i>刪除
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                          <div v-else>
                            <el-button
                              type="primary"
                              size="small"
                              v-if="$store.state.userInfo.adminId == 1"
                              @click="addToEdit(scope.row.id)"
                            >
                              <i class="el-icon-edit-outline" style="font-weight: 900;"></i> 编辑
                            </el-button>
                            <el-button
                              size="small"
                              type="info"
                              @click="$refs.RoomTable.clearSelection()"
                              style="margin-right: 8px;"
                              v-else
                            >禁止操作</el-button>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="pager">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      @current-change="pageBin2"
                      :page-size="data.pageSize"
                      :total="data.total"
                    ></el-pagination>
                  </div>
                </el-tab-pane>
              </el-tabs> -->

            </template>
          </el-main>
        </el-container>
      </el-container>
    </el-container>




  </div>
</template>
<script>
  import imgHeadUrl from '@/assets/img/admin/user/2.jpg'
  import imgVipUrl from'@/assets/img/icon/vip.png'
  export default {
    data() {
      return {
        activeName:'first',
        screenHeight: document.body.clientHeight,
        tableHeight:{
          height:window.innerHeight-126 + "px"
        },
        imgHeadUrl:imgHeadUrl,
        imgVipUrl:imgVipUrl,
        categoryIndex:0,
        queryDb:{
          query:''
        },
        form:{
          id: 0,
          age: 0,
          creationTime: "",
          gender: "0",
          level: "0",
          mail: "",
          memberId: "",
          name: "",
          phones: "",
          remark: "",
          storeId: "",
          storeName: "",
        },
        loading:false,
        data: {
          list: [],
          pageSize: 0,
          total: 0,
        },
        page: 1,
      }
    },
    mounted() {
      let that = this
      // 监听屏幕高度
      window.onresize = () => {
        return (() => {
          window.screenHeight = document.body.clientHeight;
          that.screenHeight = window.screenHeight;
        })();
      };
      that.getList()
    },
    methods:{
      // unix转时间格式
      unixToTime(date){
        return this.$atk.unixToTime("Y-m-d",date)
      },
      // 列表
      getList(page){
        let that = this;
        let param = that.$atk.json_to_url({
          page: page || that.page,
          query: that.queryDb.query,
        });
        this.$http.GET(
          "/v1/booking/getCustomerList?" + param, (ret)=> {
            if (ret.errcode == 0) {
                let list = ret.data.list;
                for(let i in list){
                  list[i].creationTime = that.unixToTime(list[i].creationTime)
                }
                that.data = {
                ...ret.data,
                list:list
              };
            } else if (ret.errcode == 1) {
              that.$message({
                message: ret.errmsg,
                type: "warning",
              });
            }
          }, (err)=> {
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      },
      // 分页
      pageBtn(val) {
        this.page = val;
        this.getList(val);
      },
      // 重置
      resetForm(){
        let that = this
        that.formTitle = ''
        that.formStatus = ''
        that.categoryIndex = 0
        that.form = {
          id: 0,
          age: 0,
          creationTime: "",
          gender: "0",
          level: "0",
          mail: "",
          memberId: "",
          name: "",
          phones: "",
          remark: "",
          storeId: "",
          storeName: "",
        }
      },
      // 处理创建
      handleCreate(val, item){
        let that = this
        switch (val) {
            case 'create':
              that.formTitle = '添加預約'
            break;
          case 'redact':
            that.formTitle = '編輯預約'
            break;
          case 'info':
            that.resetForm()
            that.formTitle = '顾客信息'
            that.categoryIndex = 'item-' + item.id
            that.form ={
              ...item,
              gender:item.gender.toString()
            }
            break;
          case 'submit':
            that.setAudit()
            break;
        }
        if (val != 'submit') {
          that.formStatus = val
        }
      },
      pageBin1(){},
      pageBin2(){},
    },
    watch: {
      // 监听屏幕高度改变表格高度
      screenHeight(){
        // 初始化表格高度
        this.tableHeight.height = window.innerHeight-126 + "px";
      },
      "queryDb.query":function(){
        this.getList();
      }
    },
  }
</script>
<style scoped lang="scss">


  .el-header{
    background-color: #fff;
    color: #333;
    border: 1px solid #eee;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
    font-size: 14px;
    .header-item{
      float: left;
      box-sizing: border-box;
    }
    .query{
      width: 299px;
      height: 100%;
      padding-left: 6px;
      line-height: 60px;
      border-right: 1px solid #eee;
      .el-input-group__prepend .el-select{
        width: 90px;
      }
    }
    .nav{
      width: calc(100% - 300px);
      .menu{
        line-height: 60px;
        padding-left: 20px;
      }
      .title{
        line-height: 60px;
        font-weight: 900;
        font-size: 20px;
        text-align: center;
      }
      .date{
        line-height: 60px;
        text-align: right;
        font-size: 12px;
        padding-right: 10px;
      }
    }
  }

  .el-aside {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 20px;
    border-right: 1px solid #eee;
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-bottom-left-radius: 8px;
    .pager{
      margin-bottom: 50px;
    }
    .item{
      font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
      font-size: 14px;
      color: #5e6d82;
      padding: 10px;
      border-bottom: 1px solid #eee;
      .store-title{
        position: absolute;
        right: 10px;
      }
      .head{
        height: 63px;
        text-align: left;
        .el-avatar,.title,.name{
          text-align: left;
        }
        .vip{
          position: absolute;
          width: 23px;
          top:57px;
          left: 45px;
        }
        .name,.date{
          height: 25px;
        }
        .date{
          font-size: 12px;
          text-align: right;
          //color: #92aaca;
        }
      }
      .booking-date{
        line-height: 1.5em;
        text-align: left;
      }
    }
    .active{
      background-color: #409EFF;
      color: #fff;
      cursor: pointer;
      border-radius: 3px;
      border-bottom: 1px solid #fff;
    }
    .item:hover{
      //background-color: #71abff;
      //color: #fff;
      cursor: pointer;
      //border-radius: 3px;
      //border-bottom: 1px solid #fff;
    }
  }

  .el-main {
    background-color: #eef3f8;
    color: #333;
    padding: 10px;
  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }

  .sup-success,.sup-info,.sup-danger{
    position: absolute;
    top: 12px;
    left: -6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: translateY(-50%) translateX(100%);
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #fff;
  }
  .sup-success,.bg-success{
    background-color: #67C23A;
  }
  .sup-info,.bg-info{
    background-color: #909399;
  }
  .sup-danger,.bg-danger{
    background-color: #F56C6C;
  }
  .bg-success,.bg-info,.bg-danger{
    color: #fff;
    border-radius: 3px;
  }
  /*内容*/
  .user-card{
    position: relative;
    width: 95.9%;
    padding: 12px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
    font-size: 14px;
    h3{
      margin: 0;
    }
    .text{
      word-wrap: break-word;
    }
    .bg{
      height: 128px;
      .item{
        height: 128px;
        float: left;
      }
      .avatar{
        width: 135px;
        .vip{
          position: absolute;
          width: 38px;
          top:118px;
          left: 98px;
        }
      }
      .info{
        width: calc(100% - 160px);
        padding: 0 10px 0 10px;
        .name,.age,.phone,.store{
          padding-bottom: 6px;
        }
      }
      .date{
        .time{
          font-size: 16px;
          text-align: right;
          font-weight: bold;
        }
      }
    }
  }
</style>
